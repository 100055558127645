<template>
  <div>
    <b-row>
      <b-col cols="8">
        <h5>•소비랑 분리 (단위환산, 1MJ=0.278kWh, 1Mcal = 1.163kWh)</h5>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label-for="useYyCd"
        >
          <v-select
            id="useYyCd"
            v-model="mainSelected.useYyLasooCd"
            label="cdNm"
            taggable
            push-tags
            :options="commCodeData.USE_YY_CD"
            @input="onClickEnergyUseInfo($event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      id="lassoStmt"
      class="table-style"
      :items="items"
      :fields="fields"
      resposive
      sticky-header
      style="white-space:nowrap;"
    />
  </div>
</template>
<script>
import { BTable, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import { mapGetters } from "vuex";
import vSelect from 'vue-select'

export default {
  components: {
    BTable, BRow, BCol, vSelect, BFormGroup
  },
  props: {
    mgmBldPkLasso: {
      type: Array,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      commCodeData: "main/getCommCodeData",
      mainSelected: "main/getSelectedCodeData",
    }),
  },
  data() {
    return {
      fields: [
        {
          key: 'mainpurpsnm', label: '건물용도',
        },
        {
          key: 'buildcnt', label: '건물 수(개)',
        },
        {
          key: 'energyqty', label: '에너지 소비량(kWh/m²)',
        },
        {
          key: 'energy1st', label: '1차 에너지 소비량(kWh/m²)',
        },
        {
          key: 'energyco2', label: 'CO2 배출량(tCO₂/m²)',
        },
        {
          key: 'totareasum', label: '연면적 합계(m²)',
        },
        {
          key: 'totareaavg', label: '연면적 평균(m²)',
        },
      ],
      items: [],

    }
  },
  created() {
    // 페이지 생성 시 데이터 불러옴
    this.onClickEnergyUseInfo()
  },
  methods: {
    onClickEnergyUseInfo() {
      this.$store.dispatch("main/FETCH_BUILD_LASSO_STMT_DATA", {
        dataCode: "buildLassoStmt",
        mgmBldPkLasso: this.mgmBldPkLasso,
        useYyLasooCd: this.mainSelected.useYyLasooCd.cdId,
      }).then(() => {
        this.items = this.$store.state.main.buildLassoStmt;
      }).catch(() => {
        console.error('오류')
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
#lassoStmt th {
  text-transform: none;
}
</style>
<style scoped>
.table-style {
  /*position: relative;
  white-space: nowrap;*/

}

</style>
