<template>
  <div>
    <h5>
      * 선택 건물 리스트
      <code class="downBtn" @click="excelDown()">
        <feather-icon icon="DownloadIcon" />
        엑셀다운로드</code
      >
    </h5>
    <b-table
      id="lassoChoice"
      :fields="fields"
      :items="items"
      class="table-style"
      resposive
      sticky-header
      style="white-space:nowrap"
    />
  </div>
</template>
<script>
import { BTable } from 'bootstrap-vue';
import { utils } from '@/utils/commUtils';
import { mapGetters } from 'vuex';

export default {
  components: {
    BTable,
  },
  props: {
    mgmBldPkLasso: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      mainSelected: 'main/getSelectedCodeData',
      filterSelected: 'filter/getFilterFetchedInfo',
      result: 'filter/getResult',
    }),
    checkUseYy() {
      return this.mainSelected.useYyLasooCd.cdId;
    },
  },
  watch: {
    checkUseYy(val) {
      this.mainSelected.useYyLasooCd.cdId = val;
      // 페이지 생성 시 데이터 불러옴
      this.$store
        .dispatch('main/FETCH_BUILD_LASSO_CHOICE_DATA', {
          dataCode: 'buildLassoChoice',
          mgmBldPkLasso: this.mgmBldPkLasso,
          useYyLasooCd: this.mainSelected.useYyLasooCd.cdId,
        })
        .then(() => {
          this.items = this.$store.state.main.buildLassoChoice;
        })
        .catch(() => {
          console.error('오류');
        });
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'bldnm',
          label: '건물명',
        },
        {
          key: 'addr',
          label: '주소',
        },
        {
          key: 'mainpurpsnm',
          label: '건물용도',
        },
        {
          key: 'totarea',
          label: '연면적(m²)',
        },
        {
          key: 'energyqty',
          label: '에너지소비량(kWh/m²)',
        },
        {
          key: 'sudoqty',
          label: '수도소비량 (Ton)',
        },
      ],
      items: [],
    };
  },
  created() {
    this.loadBuildLassoChoice();
  },
  methods: {
    excelDown() {
      // utils.fnAlert(this, '다운로드 권한이 없습니다');
      utils
        .fnConfirm(this, '다운로드 하시겠습니까?', '엑셀 다운로드시 일정 시간이 소요될 수 있습니다.')
        .then(async (result) => {
          if (result.value) {
            const aJson = {};

            aJson.useYyCd = this.mainSelected.useYyLasooCd;
            aJson.openSvcId = this.mgmBldPkLasso;

            await this.$store.dispatch('filter/DOWNLOAD_EXCEL', aJson);
            if (!this.result.resultFlag) {
              utils.fnAlert(this, this.result.resultMsg);
            }
          }
        });
    },
    loadBuildLassoChoice() {
      // 페이지 생성 시 데이터 불러옴
      this.$store
        .dispatch('main/FETCH_BUILD_LASSO_CHOICE_DATA', {
          dataCode: 'buildLassoChoice',
          mgmBldPkLasso: this.mgmBldPkLasso,
          useYyLasooCd: this.mainSelected.useYyLasooCd.cdId,
        })
        .then(() => {
          this.items = this.$store.state.main.buildLassoChoice;
        })
        .catch(() => {
          console.error('오류');
        });
    },
  },
};
</script>
<style>
#lassoChoice th {
  text-transform: none;
}
</style>
<style scoped>
.table-style {
  /*position: relative;*/
  /*white-space: nowrap;*/
}

.downBtn {
  cursor: pointer;
}

.downBtn:hover {
  background-color: #28c76f;
  color: #ffffff;
}
</style>
