<template>
  <b-table
    id="mapArea"
    class="table-style"
    responsive
    :items="items"
    :fields="fields"
    sticky-header
    style="white-space:nowrap;"
  />
</template>

<script>
import { BTable } from 'bootstrap-vue';
import { mapGetters } from "vuex";

export default {
  components: {
    BTable,
  },
  props: {
    mapType: {
      type: String,
      required: true,
    },
    sigunguId: {
      type: String,
      required: true,
    },
    dongId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

      sigunguYn: true,

      fields: [
        {
          key: this.mapType === 'sigungu' ? 'dongNm' : 'mainPurpsNm', label: this.mapType === 'sigungu' ? '동' : '건물유형',
        },
        {
          key: 'bldCnt', label: '건물 수(개)',
        },
        {
          key: 'buildEnergyQty', label: '에너지 소비량(kWh/m²)',
        },
        {
          key: 'buildEnergy1st', label: '1차 에너지 소비량(kWh/m²)',
        },
        {
          key: 'buildEnergyCo2', label: 'CO2 배출량(tCO₂/m²)',
        },

      ],
      items: [],
			mainPurpsCdArr: [],
    };
  },
  computed: {
    ...mapGetters({
      mapAreaData: "main/getMapAreaData",
			mainSelected: 'main/getSelectedCodeData',
    }),
  },
  mounted() {

  },
  created() {
    if (this.mapType !== "sigungu") {
      this.sigunguYn = false;
    }
    // 상속받은 값으로 데이터 조회
		this.mainSelected.MAIN_PURPS_CD.forEach((item) => {
      this.mainPurpsCdArr.push(item.cdId);
    });
    this.$store.dispatch("main/FETCH_MAP_AREA_DATA", {
      dataCode: "mapAreaData",
      mapType: this.mapType,
      sigunguId: this.sigunguId,
      dongId: this.dongId,
			mainPurpsCd: this.mainPurpsCdArr,
    }).then(() => {
      this.items = this.$store.state.main.mapAreaData;
      this.$store.dispatch('main/FETCH_BUILD_READ_HISTORY_DATA', {
        sigunguId: this.sigunguId,
        dongId: this.dongId,
      });
    }).catch(() => {
      console.error('오류');
    });
  },
};
</script>
<style>
#mapArea th {
  text-transform: none;
}
</style>
