<template>
  <div>
    <!-- 용도별 통계 요약   -->
    <build-lasso-info-stmt-component
      :mgm-bld-pk-lasso="mgmBldPkLasso"
    />
    <!-- 선택 건물 리스트-->
    <build-lasso-info-choice-component
      :mgm-bld-pk-lasso="mgmBldPkLasso"
    />
  </div>
</template>

<script>

import Ripple from "vue-ripple-directive";
import BuildLassoInfoStmtComponent from "@/views/building/buildBaiscInfo/BuildLassoInfoStmtComponent";
import BuildLassoInfoChoiceComponent from "@/views/building/buildBaiscInfo/BuildLassoInfoChoiceComponent";

export default {
  components: {
    BuildLassoInfoChoiceComponent,
    BuildLassoInfoStmtComponent

  },
  directives: {
    Ripple,
  },
  props: {
    mgmBldPkLasso: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {

    };
  },
}
</script>

<style scoped>

</style>
