<template>
  <div>
    <div>
      <b-button-group class="mb-1">
        <b-button
          :variant="current === 'building-service' ? 'primary' : 'outline-primary'"
          :class="current === 'building-service' ? '' : 'bg-light'"
          size="sm"
          @click="goto2DMap()"
        >
          <feather-icon class="mr-50" icon="MapIcon" />
          <span class="align-middle">2D 지도 보기</span>
        </b-button>
        <b-button
          :variant="current === 'building-service-3d' ? 'primary' : 'outline-primary'"
          :class="current === 'building-service-3d' ? '' : 'bg-light'"
          size="sm"
          @click="goto3DMap()"
        >
          <feather-icon class="mr-50" icon="GlobeIcon" />
          <span class="align-middle">3D 지도 보기</span>
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>
<script>
import { BButton, BButtonGroup, BModal, VBModal } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AuthProfile from '~/auth/AuthProfile.vue';

export default {
  components: {
    BButtonGroup,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    // 버튼 색깔 설정
    current: {
      type: String,
      required: true,
      default: 'building-service',
    },
  },
  computed: {
    roleId() {
      return this.$store.state.auth.roleId;
    },
  },
  methods: {
    goto2DMap() {
      gis.search.goto2DMap(map);
    },
    goto3DMap() {
      gis.search.goto3DMap(map);
    },
  },
};
</script>

<style scoped></style>
